"use client";

import React from "react";
import Image from "next/image";
import { signIn } from "next-auth/react";
import { Button } from "@mui/material";
import styles from "./Landing.module.css";

export default function Landing() {
    return (
        <div className={styles.container}>
            <Image
                src="/images/ltlinx.png"
                alt="LTLinx Logo"
                width={400}
                height={300}
            ></Image>
            <h1>Welcome to LTLinx</h1>
            <Button variant="contained" onClick={() => signIn("google")}>
                Get Started With Google
            </Button>
            <Button variant="contained" onClick={() => signIn("cognito")}>
                Get Started With Email
            </Button>
        </div>
    );
}
